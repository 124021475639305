import AOS from 'aos';
import scrollSpy from 'simple-scrollspy';

$(() => {
  AOS.init();
  $('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          event.preventDefault();
          window.scrollTo(0, target.offset().top - 120)
        }
      }
    });

  const options = {
    sectionClass: '.scrollspy',
    menuActiveTarget: '.scrollspy-link',
    offset: 130,
  };
  scrollSpy('#pageShowMenu', options);

  var windowWidth = $(window).width();
  let pageShowMenu = document.getElementById("pageShowMenu");
  function menuScroll() {
    setTimeout(() => {
      let item = $('.scrollspy-link.active').outerWidth();
      let item_left_size = $('.scrollspy-link.active').offset().left;
      let scrollTo = item_left_size - (windowWidth / 2 - item / 2);
      pageShowMenu.scrollLeft += scrollTo;
    }, 100);
  }

  let observer = new MutationObserver(
    // (mutationRecords) => console.log(mutationRecords)
    (mutationRecords) => menuScroll()
  );


  if (windowWidth <= 680 && $('.fixed-top-menu-items').length >= 1) {
    observer.observe(pageShowMenu, {
      attributes: true,
      subtree: true,
    });
  }


  // clinic filter
  $('.clinic-filter-item[data-filter]').on('click', function() {
    let id = $(this).attr('data-filter');
    $(this).siblings().not(".divider").removeClass("is-active");
    $(this).addClass("is-active");
    if(id === 'all') {
      $('.clinic-item').show();
    }else {
      $('.clinic-item').hide();
      $('.clinic-item[data-clinic-area=' + id+']').show();
    }
  });

  // accordion
  $('[data-toggle=collapse]').on('click', function () {
    var controls = $(this).attr('aria-controls');
    var collapse_expanded = $(this).attr('aria-expanded');
    var collapse_content = $('[aria-label=' + controls + ']');
    $(this).toggleClass('is-active');
    collapse_content.toggleClass('is-active');
    if (collapse_expanded === "true") {
      collapse_content.hide("fast");
      $(this).attr('aria-expanded', false);
    } else {
      $(this).attr('aria-expanded', true);
      collapse_content.show("fast");
    }
  });

  // custom-editor iframe responsive
  if ($('.custom-editor iframe').length >= 1) {
    $('.custom-editor iframe').wrap('<div class="iframe-outer"></div>');
  }

  // form input select focused
  document.querySelectorAll('input').forEach(input => {
    input.addEventListener('input', () => {
      if (input.value.length > 0) {
        input.labels.forEach(label => {
          label.classList.add('focused')
        })
      }
    })
    input.addEventListener('focus', () => {
      input.labels.forEach(label => {
        label.classList.add('focused')
      })
    })

    input.addEventListener('blur', () => {
      if (input.value.length <= 0) {
        input.labels.forEach(label => {
          label.classList.remove('focused')
        })
      }
    })
  });

  document.querySelectorAll('select').forEach(select => {
    select.addEventListener('focus', () => {
      select.labels.forEach(label => {
        label.classList.add('focused')
      })
    })

    select.addEventListener('blur', () => {
      if (select.value.length <= 0) {
        select.labels.forEach(label => {
          label.classList.remove('focused')
        })
      }
    })
  })
});
