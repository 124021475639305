import 'aos/dist/aos.css';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import 'public/style.scss';
import 'core-js';
import Cookies from 'js-cookie';
import 'public/main';
import 'public/header_footer';
import 'public/custom_swiper';
import 'select2';
import Rails from '@rails/ujs';
Rails.start();

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;
