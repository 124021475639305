import Swiper, { Navigation, Autoplay, EffectFade, Parallax, Thumbs } from 'swiper';

Swiper.use([Navigation, Autoplay, EffectFade, Parallax, Thumbs]);

$(() => {
  let indexBanner = new Swiper('#indexBanner', {
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    speed: 1000,
    loop: true,
    parallax: true,
  });

  let article = new Swiper('#articleSwiper', {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 15,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      680: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      900: {
        spaceBetween: 32,
        slidesPerView: 3,
      },
    },
  });
  let showcase = new Swiper('#showcaseSwiper', {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 15,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      680: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1000: {
        spaceBetween: 25,
        slidesPerView: 4,
      },
    },
  });

  let video = new Swiper('#videoSwiper', {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 15,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      680: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      900: {
        spaceBetween: 32,
        slidesPerView: 3,
      },
    },
  });

  let doctors = new Swiper('#doctorsSwiper', {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 15,
    loop: true,
    autoplay: {
      delay: 3500,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      680: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      960: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      1100: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
  });

  let comboSwiper = new Swiper('#comboSwiper', {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 15,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      500: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1000: {
        spaceBetween: 10,
        slidesPerView: 4,
      },
    },
  });

  // clinic slider
  let clinicThumbs = new Swiper('#clinicThumbs', {
    slidesPerView: 4,
    spaceBetween: 6,
    watchSlidesVisibility: true,
    breakpoints: {
      680: {
        slidesPerView: 6,
      },
    },
  })

  let clinicSwiper = new Swiper('#clinicSlider', {
    slidesPerView: 1,
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    speed: 1000,
    loop: true,
    parallax: true,
    thumbs: {
      swiper: clinicThumbs,
    },
  })
});
