$(() => {
  if ($(window).scrollTop() > 50) {
    $('.header').addClass('fixed-header');
  } else {
    $('.header').removeClass('fixed-header');
  };


  $('.header-menu-btn, .header-menu-mask').on('click', function () {
    let id = $(this).data('target');
    let expanded = $(id).attr('aria-expanded');
    if (expanded == 'true'){
      $(id).removeClass('is-open');
      $('.header-menu-btn').removeClass('is-open');
      $(id).addClass('is-fade');
      setTimeout(() => {
        $(id).removeClass('is-fade');
        $(id).attr('aria-expanded', 'false');
      }, 400);
    }else {
      $(id).addClass('is-open');
      $('.header-menu-btn').addClass('is-open');
      $(id).attr('aria-expanded', 'true');
    }
  });
  document.addEventListener('click', function (event) {
    var dropdowns = document.querySelectorAll('[data-target="menu-dropdown"]');
    dropdowns.forEach(function (dropdown) {
      var dropdownContent = dropdown.querySelector('.header-menu-dropdown');
      if (!dropdown.contains(event.target) && dropdownContent.style.display === 'block') {
        dropdownContent.style.display = 'none';
        dropdown.classList.remove('is-show');
      }
    });
  });

  document.querySelectorAll('[data-target="menu-dropdown"]').forEach(function (dropdown) {
    dropdown.querySelector('.header-menu-link').addEventListener('click', function (event) {
      var dropdownContent = dropdown.querySelector('.header-menu-dropdown');
      if (dropdownContent.style.display === 'block') {
        dropdownContent.style.display = 'none';
        dropdown.classList.remove('is-show');
      }else {
        dropdownContent.style.display = 'block';
        dropdown.classList.add('is-show');
      }
    });
  });

  $('.menu-dropdown-tab').find('.tab-text').on('click', function () {
    let id = $(this).data('target');
    if ($(window).width() < 900) {
      $(this).toggleClass('is-active-mobile');
      $('.menu-dropdown-panel[aria-label=' + id + ']').toggleClass('is-active-mobile');
    }else {
      $(this).parents('.header-menu-dropdown-content').find('.tab-text').removeClass('is-active');
      $(this).parents('.header-menu-dropdown-content').find('.menu-dropdown-panel').removeClass('is-active');
      $(this).addClass('is-active');
      $('.menu-dropdown-panel[aria-label=' + id + ']').addClass('is-active');
    }
  });

  menuDropdownPanelShow();
  window.addEventListener('resize', function () {
    setTimeout(() => {
      menuDropdownPanelShow();
    }, 1000);
  });


  $('.back-to-top').on('click', function () {
    $('html, body').animate({ scrollTop: 0 }, 300, 'swing');
  });
});

$(window).on('scroll', function () {
  if ($(window).scrollTop() > 100) {
    $('.header').addClass('fixed-header');
    $('.fixed-top-page-info').addClass('show');
  } else {
    $('.header').removeClass('fixed-header');
    $('.fixed-top-page-info').removeClass('show');
  };
  if ($(window).scrollTop() > 500) {
    $('.back-to-top').fadeIn();
  } else {
    $('.back-to-top').fadeOut();
  };
});

function menuDropdownPanelShow() {
  if ($(window).width() >= 900) {
    $('.header-menu-dropdown-content').each(function () {
      $(this).find('.menu-dropdown-tab').find('.tab-text').first().addClass('is-active');
      $(this).find('.menu-dropdown-panel').first().addClass('is-active');
    });
  }
}
